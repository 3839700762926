@charset "UTF-8";
/*!
 * Bootstrap-select v1.10.0 (http://silviomoreto.github.io/bootstrap-select)
 *
 * Copyright 2013-2016 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */
select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/ }

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1; }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2; }

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px; }

.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }

.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  z-index: auto; }

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0; }

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%; }

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed; }

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important; }

.bootstrap-select.btn-group.bs-container {
  position: absolute; }

.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left; }

.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative; }

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff; }

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em; }

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static; }

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px; }

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/*# sourceMappingURL=bootstrap-select.css.map */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.navbar-primary .navbar-inventory .navbar-cats,
.navbar-primary .navbar-inventory .navbar-links, .tab-bar, .cta-group {
  margin: 0;
  padding: 0;
  list-style: none; }

.clearfix:after, .tile-grid:after {
  content: '';
  display: block;
  clear: both; }

.u-margin-x-0,
.u-margin-left-0 {
  margin-left: 0 !important; }

.u-margin-x-0,
.u-margin-right-0 {
  margin-right: 0 !important; }

.u-margin-y-0,
.u-margin-top-0 {
  margin-top: 0 !important; }

.u-margin-y-0,
.u-margin-bottom-0 {
  margin-bottom: 0 !important; }

.u-undermark:after {
  content: "";
  display: block;
  height: 4px;
  width: 100px;
  background: #333333;
  margin: 0.6em auto; }

.u-undermark--margin-bottom-0:after {
  margin-bottom: 0; }

.u-undermark--margin-top-0:after {
  margin-top: 0; }

.u-undermark--margin-x-0:after {
  margin-left: 0;
  margin-right: 0; }

.u-undermark--red:after {
  background: #ce2026; }

.u-undermark--dark-green:after {
  background: #183f1d; }

.u-undermark--lime:after {
  background: #78a932; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

.btn-lime {
  color: #FFF;
  background-color: #78a932;
  border-color: transparent; }
  .btn-lime:hover, .btn-lime:focus, .btn-lime:active, .btn-lime.active {
    color: #FFF;
    background-color: #628a29;
    border-color: transparent; }
  .open .btn-lime.dropdown-toggle {
    color: #FFF;
    background-color: #628a29;
    border-color: transparent; }
  .btn-lime:active, .btn-lime.active {
    background-image: none; }
  .open .btn-lime.dropdown-toggle {
    background-image: none; }
  .btn-lime.disabled, .btn-lime.disabled:hover, .btn-lime.disabled:focus, .btn-lime.disabled:active, .btn-lime.disabled.active, .btn-lime[disabled], .btn-lime[disabled]:hover, .btn-lime[disabled]:focus, .btn-lime[disabled]:active, .btn-lime[disabled].active,
  fieldset[disabled] .btn-lime,
  fieldset[disabled] .btn-lime:hover,
  fieldset[disabled] .btn-lime:focus,
  fieldset[disabled] .btn-lime:active,
  fieldset[disabled] .btn-lime.active {
    background-color: #78a932;
    border-color: transparent; }

.bootstrap-select .btn-default {
  background: #fff;
  border-color: #ddd;
  color: #333; }

.header {
  z-index: 200; }

.footer {
  background: #1d5100;
  color: #1d5100;
  padding: 30px 0 50px; }
  @media (max-width: 768px) {
    .footer {
      margin-bottom: 58px; } }
  .footer .footer-heading {
    font-weight: bold;
    font-family: "museo-slab", serif;
    font-size: 20px;
    margin: 30px 0;
    color: #78a932;
    text-transform: uppercase; }
  .footer .footer-contact,
  .footer .footer-hours,
  .footer .footer-links {
    font-size: 14px;
    line-height: 24px;
    color: #fff; }
    .footer .footer-contact a,
    .footer .footer-hours a,
    .footer .footer-links a {
      display: block;
      color: #fff; }
  .footer .footer-hours,
  .footer .footer-links {
    text-transform: uppercase; }

@media (max-width: 992px) {
  .navbar-primary .navbar-header {
    float: none;
    text-align: center; } }

.navbar-primary .navbar-brand {
  width: auto;
  height: auto;
  padding: 30px 30px 35px 5vw;
  margin-bottom: 10px; }
  .navbar-primary .navbar-brand img {
    max-width: 271px;
    min-width: 160px;
    width: 15vw; }
  @media (max-width: 992px) {
    .navbar-primary .navbar-brand {
      padding: 15px;
      width: auto; } }
  @media (max-width: 992px) and (max-width: 768px) {
    .navbar-primary .navbar-brand {
      text-align: center;
      width: 180px;
      padding-bottom: 30px;
      float: none;
      display: block;
      margin: 0 auto; } }
  @media (max-width: 992px) {
      .navbar-primary .navbar-brand img {
        width: 180px; } }
    @media (max-width: 992px) and (max-width: 768px) {
      .navbar-primary .navbar-brand img {
        position: relative;
        left: -27px;
        margin: 0 auto; } }

.navbar-primary .navbar-toggle {
  position: absolute;
  right: 0px;
  top: 8px;
  border: 1px solid #ccc;
  margin-top: 30px;
  margin-right: 30px;
  padding: 6px; }
  .navbar-primary .navbar-toggle .icon-bar {
    background: #183f1d; }

.navbar-primary .navbar-nav {
  padding: 10px 0 10px;
  border-top: 1px solid #EEE;
  font-size: 18px; }
  @media (min-width: 1181px) {
    .navbar-primary .navbar-nav {
      padding: 50px 0 0;
      border-top-width: 0; } }
  @media (min-width: 1381px) {
    .navbar-primary .navbar-nav {
      padding: 63px 0 0; } }
  .navbar-primary .navbar-nav > li {
    font-size: 18px; }
    .navbar-primary .navbar-nav > li > a {
      padding: 5px 10px;
      color: #4f4f4f;
      font-weight: bold;
      text-transform: uppercase;
      border: 1px solid transparent; }
      .navbar-primary .navbar-nav > li > a:hover, .navbar-primary .navbar-nav > li > a:active, .navbar-primary .navbar-nav > li > a:focus {
        background: transparent;
        border-color: #dddddd; }
      .navbar-primary .navbar-nav > li > a.featured {
        color: #ce2026; }
    @media (min-width: 1181px) {
      .navbar-primary .navbar-nav > li {
        font-size: 16px; }
        .navbar-primary .navbar-nav > li > a {
          padding: 10px 12px; } }
    @media (min-width: 1381px) {
      .navbar-primary .navbar-nav > li {
        font-size: 18px; } }

.navbar-primary .navbar-contact {
  height: auto;
  padding: 43px 5vw 0 30px;
  font-size: 18px; }
  @media (max-width: 992px) {
    .navbar-primary .navbar-contact {
      padding-top: 0;
      margin-top: -70px; } }
  @media (max-width: 768px) {
    .navbar-primary .navbar-contact {
      display: none; } }
  @media (min-width: 1181px) {
    .navbar-primary .navbar-contact {
      padding: 50px 5vw 0 30px; } }
  @media (min-width: 1381px) {
    .navbar-primary .navbar-contact {
      padding: 52px 5vw 0 30px; } }
  .navbar-primary .navbar-contact .navbar-phone {
    font-size: 20px;
    padding-left: 26px;
    color: #183f1d;
    display: block;
    background: transparent url(/assets/img/components/navbar/icon-phone.png) 0 center no-repeat; }
    .navbar-primary .navbar-contact .navbar-phone:hover {
      text-decoration: none;
      cursor: default; }
    @media (max-width: 992px) {
      .navbar-primary .navbar-contact .navbar-phone {
        padding-left: 30px;
        padding-right: 30px; } }
    @media (max-width: 1180px) {
      .navbar-primary .navbar-contact .navbar-phone {
        background-size: 20px 20px; } }
    @media (min-width: 1181px) {
      .navbar-primary .navbar-contact .navbar-phone {
        font-size: 24px;
        padding-left: 32px; } }
    @media (min-width: 1381px) {
      .navbar-primary .navbar-contact .navbar-phone {
        font-size: 36px;
        padding-left: 40px; } }
  @media (min-width: 769px) {
    .navbar-primary .navbar-contact {
      float: right; } }

@media (max-width: 480px) {
  .navbar-primary .navbar-main {
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    max-height: none;
    z-index: 100;
    background: #FFF; } }

@media (max-width: 1180px) {
  .navbar-primary .navbar-main > .navbar-nav {
    clear: left; } }

@media (min-width: 769px) {
  .navbar-primary .navbar-main > .navbar-nav {
    float: none;
    text-align: center; }
    .navbar-primary .navbar-main > .navbar-nav > li {
      float: none;
      display: inline-block; } }

.navbar-primary .navbar-inventory {
  width: 100%;
  padding: 0;
  margin-top: -1px !important;
  background: #f3f3f3;
  border-width: 2px 0 0;
  border-color: #ce2026; }
  @media (min-width: 769px) {
    .navbar-primary .navbar-inventory {
      width: 650px;
      float: left; } }
  .navbar-primary .navbar-inventory a {
    text-decoration: none; }
    .navbar-primary .navbar-inventory a:hover {
      color: #78a932; }
  .navbar-primary .navbar-inventory .navbar-cats,
  .navbar-primary .navbar-inventory .navbar-links {
    width: 100%;
    float: none;
    overflow: hidden; }
  @media (min-width: 769px) {
    .navbar-primary .navbar-inventory .navbar-cats {
      width: 360px;
      margin-bottom: 32px;
      float: right; } }
  .navbar-primary .navbar-inventory .navbar-cat a {
    display: block;
    width: 140px;
    height: 100px;
    float: left;
    background-image: url(/assets/img/components/navbar/nav-sprite-vehicles.png);
    margin: 0 8px;
    background-position-y: -10px;
    padding: 72px 0 0;
    text-align: center; }
    .navbar-primary .navbar-inventory .navbar-cat a:hover {
      background-position-y: 111px; }
    @media (min-width: 769px) {
      .navbar-primary .navbar-inventory .navbar-cat a {
        margin: 0 20px; } }
  .navbar-primary .navbar-inventory .navbar-cat-cars a {
    background-position-x: 0; }
  .navbar-primary .navbar-inventory .navbar-cat-vans a {
    background-position-x: 140px; }
  .navbar-primary .navbar-inventory .navbar-cat-trucks a {
    background-position-x: 420px; }
  .navbar-primary .navbar-inventory .navbar-cat-suvs a {
    background-position-x: 280px; }
  .navbar-primary .navbar-inventory .navbar-links {
    margin: 10px 0;
    text-align: left; }
    @media (min-width: 769px) {
      .navbar-primary .navbar-inventory .navbar-links {
        width: 288px;
        float: left;
        border-right: 1px solid #FFF;
        margin: 20px 0; } }
  .navbar-primary .navbar-inventory a {
    display: block;
    white-space: nowrap;
    padding: 9px 20px;
    color: #4f4f4f;
    font-size: 16px;
    text-transform: uppercase; }

.poster {
  position: relative;
  background: transparent none center center no-repeat;
  background-size: cover;
  z-index: 20; }

.poster--overlay:after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 30; }

.poster--center {
  text-align: center; }

.poster-body {
  position: relative;
  z-index: 40; }

.poster--4x3 {
  min-height: 75vw; }

.poster--4x3.poster--vcenter {
  padding: 0 30px;
  padding-top: 37.5vw; }
  .poster--4x3.poster--vcenter .poster-body {
    transform: translateY(-50%); }

.poster--16x9 {
  min-height: 56vw; }

.poster--16x9.poster--vcenter {
  padding: 0 30px;
  padding-top: 28vw; }
  .poster--16x9.poster--vcenter .poster-body {
    transform: translateY(-50%); }

.poster--2x1 {
  min-height: 50vw; }

.poster--2x1.poster--vcenter {
  padding: 0 30px;
  padding-top: 25vw; }
  .poster--2x1.poster--vcenter .poster-body {
    transform: translateY(-50%); }

.poster--3x1 {
  min-height: 33vw; }

.poster--3x1.poster--vcenter {
  padding: 0 30px;
  padding-top: 16.5vw; }
  .poster--3x1.poster--vcenter .poster-body {
    transform: translateY(-50%); }

.poster--8x3 {
  min-height: 37vw; }

.poster--8x3.poster--vcenter {
  padding: 0 30px;
  padding-top: 18.5vw; }
  .poster--8x3.poster--vcenter .poster-body {
    transform: translateY(-50%); }

.poster--30x13 {
  min-height: 43vw; }

.poster--30x13.poster--vcenter {
  padding: 0 30px;
  padding-top: 21.5vw; }
  .poster--30x13.poster--vcenter .poster-body {
    transform: translateY(-50%); }

.poster-title {
  font-family: "museo-slab", serif;
  font-weight: bold;
  color: #fff;
  margin: 0; }
  .poster-title {
    font-size: 24px; }
    @media screen and (min-width: 400px) {
      .poster-title {
        font-size: calc(24px + 40 * (100vw - 400px) / 624); } }
    @media screen and (min-width: 1024px) {
      .poster-title {
        font-size: 64px; } }

.poster-form {
  max-width: 624px;
  background: rgba(255, 255, 255, 0.3);
  padding: 30px;
  margin: 0 auto;
  border-radius: 3px;
  font-size: 14px; }
  .poster-form .btn, .poster-form .cta-group-item-link {
    padding: 10px 20px;
    text-transform: uppercase; }
  .poster-form .btn-group {
    margin-left: 5px;
    margin-right: 5px; }
  .poster-form .select-custom .btn, .poster-form .select-custom .cta-group-item-link {
    color: #333;
    background-color: #fff;
    border-color: transparent;
    padding-right: 25px; }
  .poster-form .select-custom .dropdown-menu {
    border-radius: 3px;
    text-align: left;
    text-transform: uppercase; }

.poster-cta {
  display: none;
  padding: 10px 20px;
  margin-top: 1.2em;
  text-transform: uppercase; }
  @media (min-width: 769px) {
    .poster-cta {
      display: inline-block; } }
  @media (min-width: 993px) {
    .poster-cta {
      margin-top: calc(30px + 1.2em); } }

.tab-bar {
  background: #183f1d;
  min-width: 320px;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: none; }
  @media (max-width: 767px) {
    .tab-bar {
      display: table;
      table-layout: fixed;
      width: 100%; } }
  .tab-bar li {
    display: table-cell;
    width: 25%;
    border-left: 1px solid #112d15;
    text-align: center;
    text-transform: uppercase; }
    .tab-bar li:first-child {
      border: none; }
    .tab-bar li a {
      color: #fff;
      display: block;
      padding: 0 5px; }
      .tab-bar li a:hover {
        color: #ccc; }
    .tab-bar li .fa {
      display: block;
      font-size: 20px;
      margin-bottom: 5px; }
    .tab-bar li .txt {
      font-size: 15px;
      line-height: 17px;
      display: block; }
      @media (max-width: 479px) {
        .tab-bar li .txt {
          font-size: 11px;
          line-height: 13px; } }

.deck-card {
  display: none; }
  .deck-card:focus {
    outline: none; }
  .deck-card .img-responsive {
    width: 100%; }

.deck-collection--grid {
  overflow: hidden; }

.deck-card {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 30px; }
  @media (min-width: 481px) {
    .deck-card {
      width: 50%; } }
  @media (min-width: 993px) {
    .deck-card {
      width: 33.333%; } }

.deck-title,
.deck-subtitle {
  margin: 60px 0;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  font-family: "museo-slab", serif;
  color: #333333;
  text-align: center;
  clear: both; }

.deck-subtitle {
  font-size: 28px;
  line-height: 32px;
  font-weight: normal; }

.action-bar {
  margin: 30px 0; }

.action-bar-block {
  border-bottom: 1px solid #fff;
  background-color: #f3f3f3;
  text-align: center; }
  @media (min-width: 993px) {
    .action-bar-block {
      border-bottom: none;
      border-right: 1px solid #fff; }
      .action-bar-block:last-of-type {
        border-right-width: 0; } }

.action-bar-link {
  display: block;
  margin: 0 auto; }

.action-bar-link-line1,
.action-bar-link-line2 {
  display: block; }

.article {
  margin: 30px 0; }

.article-title {
  font-family: "museo-slab", serif;
  font-weight: bold; }

.article--thin {
  padding: 0 10%; }

.tile-grid {
  margin: 30px 0; }
  .tile-grid .col-sm-6, .tile-grid .col-md-4, .tile-grid .col-md-3, .tile-grid .col-md-2, .tile-grid .col-sm-6, .tile-grid .col-sm-4, .tile-grid .col-sm-3, .tile-grid .col-sm-2 {
    padding: 0; }

.tile-grid-item {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  padding-bottom: 75%;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .tile-grid-item:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    height: 17px;
    width: 17px; }

.tile-grid-item--red .tile-grid-item-cta {
  color: #ce2026; }

.tile-grid-item--red .tile-grid-item-content {
  background: rgba(255, 255, 255, 0.7); }

.tile-grid-item--red:after {
  background-image: url(/assets/img/components/tile-grid/triangle-red.png); }

.tile-grid-item--dark-green .tile-grid-item-cta {
  color: #183f1d; }

.tile-grid-item--dark-green .tile-grid-item-content {
  background: rgba(255, 255, 255, 0.7); }

.tile-grid-item--dark-green:after {
  background-image: url(/assets/img/components/tile-grid/triangle-dark-green.png); }

.tile-grid-item--lime .tile-grid-item-cta {
  color: #78a932; }

.tile-grid-item--lime .tile-grid-item-content {
  background: rgba(255, 255, 255, 0.7); }

.tile-grid-item--lime:after {
  background-image: url(/assets/img/components/tile-grid/triangle-lime.png); }

.tile-grid-item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 25px; }

.tile-grid-item-content--vcenter {
  position: absolute;
  margin-top: 50%;
  transform: translateY(-50%); }

.tile-grid-item-title {
  color: #333333;
  font-family: "museo-slab", serif;
  font-size: 30px;
  line-height: 40px;
  font-weight: normal;
  padding: 25px; }

@media (min-width: 993px) and (max-width: 1200px) {
  .tile-grid-item-title {
    font-size: 22px;
    line-height: 28px; } }

.tile-grid-item-cta {
  padding: 25px;
  text-transform: uppercase; }

.callout {
  background-color: #f3f3f3;
  padding: 40px;
  margin-top: 30px;
  clear: both; }

.callout-text {
  font-size: 30px;
  line-height: 36px;
  font-family: "museo-slab", serif;
  color: #183f1d; }
  .callout-text a {
    color: #78a932;
    white-space: nowrap; }

.breadcrumb-bar {
  background: #f3f3f3;
  font-size: 18px;
  color: #333333;
  text-transform: uppercase;
  padding: 15px 0;
  margin-bottom: 30px;
  /* bs override*/ }
  .breadcrumb-bar .breadcrumb {
    margin-bottom: 0; }
  @media (max-width: 992px) {
    .breadcrumb-bar li:nth-of-type(2) {
      display: none; } }
  @media (max-width: 480px) {
    .breadcrumb-bar li {
      display: none; }
    .breadcrumb-bar li:first-child,
    .breadcrumb-bar li:nth-last-child(1),
    .breadcrumb-bar li:nth-last-child(2) {
      display: inline-block; } }

.breadcrumb-bar-item {
  color: #333; }
  .breadcrumb-bar-item:hover {
    color: #555;
    text-decoration: none; }

.breadcrumb-bar-item-active {
  cursor: default; }

.breadcrumb-bar-action {
  padding-top: 8px;
  padding-right: 15px; }
  .breadcrumb-bar-action a {
    color: #333; }
    .breadcrumb-bar-action a:hover {
      color: #555;
      text-decoration: none; }
  .breadcrumb-bar-action:before {
    display: none; }

.cta-group {
  overflow: hidden; }

.cta-group-item {
  display: block;
  margin-bottom: 1px; }

.cta-group--horizontal .cta-group-item {
  float: left; }

.cta-group-item-link {
  display: block; }

.filter-menu-top-spacer > .filter-menu {
  margin-top: 30px;
  margin-bottom: 30px; }

.filter-menu {
  padding: 0;
  font-size: 18px; }
  @media (max-width: 480px) {
    .filter-menu .nav-label {
      clear: both;
      margin-left: 0;
      width: 80px; } }
  .filter-menu .nav-label span {
    cursor: default !important;
    padding: 10px 0 10px 10px;
    display: block; }
  .filter-menu.nav-pills > li > a {
    border-radius: 0;
    border-bottom: 1px solid #eee;
    background: transparent;
    padding: 10px; }
    .filter-menu.nav-pills > li > a:hover {
      border-bottom: 1px solid #ce2026;
      background: transparent; }
    .filter-menu.nav-pills > li > a:focus {
      background-color: transparent; }
  .filter-menu.btn-group > .btn, .filter-menu.btn-group > .cta-group-item-link {
    float: none;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0;
    text-decoration: none !important;
    border-radius: 0; }
    .filter-menu.btn-group > .btn > a, .filter-menu.btn-group > .cta-group-item-link > a {
      border-bottom: 1px solid #eee;
      display: inline-block;
      padding: 6px 12px; }
      .filter-menu.btn-group > .btn > a:hover, .filter-menu.btn-group > .cta-group-item-link > a:hover {
        border-bottom: 1px solid #ce2026; }
    .filter-menu.btn-group > .btn.active, .filter-menu.btn-group > .active.cta-group-item-link, .filter-menu.btn-group > .btn:active, .filter-menu.btn-group > .cta-group-item-link:active {
      background-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none; }
      .filter-menu.btn-group > .btn.active > a, .filter-menu.btn-group > .active.cta-group-item-link > a, .filter-menu.btn-group > .btn:active > a, .filter-menu.btn-group > .cta-group-item-link:active > a {
        border-bottom: 2px solid #ce2026; }

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0; }

.feature-list-item {
  text-indent: -10px;
  padding-right: 40px;
  margin: 5px 0;
  line-height: 20px; }
  .feature-list-item .fa {
    color: green; }

.t-deck--vehicles .slick-dots {
  height: 35px;
  top: -50px; }

.t-deck--vehicles .deck-collection {
  margin: 30px -15px; }

.t-deck--vehicles .deck-collection--grid {
  margin-bottom: 0; }

.t-deck--vehicles .deck-card {
  padding: 0 15px;
  position: relative; }

.t-deck--vehicles .deck-card-info {
  position: absolute;
  bottom: 60px;
  left: 35px;
  right: 35px;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-left: 3px solid #ce2026;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  padding: 15px;
  text-transform: uppercase; }

.t-deck--vehicles .deck-card-info-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.t-deck--vehicles .deck-card-button {
  width: 100%;
  border-radius: 0 0 3px 3px;
  font-size: 16px;
  text-transform: uppercase;
  position: relative; }

.t-deck--vehicles .deck-card-button-icon {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent url(/assets/img/components/btn/circle-arrow-right-green.png) center center no-repeat; }

.t-deck--vehicles .deck-collection--grid .deck-card-button {
  color: #333;
  background-color: #F3F3F3;
  border-color: transparent; }
  .t-deck--vehicles .deck-collection--grid .deck-card-button:hover, .t-deck--vehicles .deck-collection--grid .deck-card-button:focus, .t-deck--vehicles .deck-collection--grid .deck-card-button:active, .t-deck--vehicles .deck-collection--grid .deck-card-button.active {
    color: #333;
    background-color: #dfdfdf;
    border-color: transparent; }
  .open .t-deck--vehicles .deck-collection--grid .deck-card-button.dropdown-toggle {
    color: #333;
    background-color: #dfdfdf;
    border-color: transparent; }
  .t-deck--vehicles .deck-collection--grid .deck-card-button:active, .t-deck--vehicles .deck-collection--grid .deck-card-button.active {
    background-image: none; }
  .open .t-deck--vehicles .deck-collection--grid .deck-card-button.dropdown-toggle {
    background-image: none; }
  .t-deck--vehicles .deck-collection--grid .deck-card-button.disabled, .t-deck--vehicles .deck-collection--grid .deck-card-button.disabled:hover, .t-deck--vehicles .deck-collection--grid .deck-card-button.disabled:focus, .t-deck--vehicles .deck-collection--grid .deck-card-button.disabled:active, .t-deck--vehicles .deck-collection--grid .deck-card-button.disabled.active, .t-deck--vehicles .deck-collection--grid .deck-card-button[disabled], .t-deck--vehicles .deck-collection--grid .deck-card-button[disabled]:hover, .t-deck--vehicles .deck-collection--grid .deck-card-button[disabled]:focus, .t-deck--vehicles .deck-collection--grid .deck-card-button[disabled]:active, .t-deck--vehicles .deck-collection--grid .deck-card-button[disabled].active,
  fieldset[disabled] .t-deck--vehicles .deck-collection--grid .deck-card-button,
  fieldset[disabled] .t-deck--vehicles .deck-collection--grid .deck-card-button:hover,
  fieldset[disabled] .t-deck--vehicles .deck-collection--grid .deck-card-button:focus,
  fieldset[disabled] .t-deck--vehicles .deck-collection--grid .deck-card-button:active,
  fieldset[disabled] .t-deck--vehicles .deck-collection--grid .deck-card-button.active {
    background-color: #F3F3F3;
    border-color: transparent; }

.t-deck--vehicles .deck-collection--grid .deck-card-button-icon {
  background: transparent url(/assets/img/components/btn/circle-arrow-right-white.png) center center no-repeat; }

.t-deck--vehicles .deck-title {
  text-transform: uppercase; }

.t-deck--vehicles .deck-subtitle {
  color: #183f1d; }

.t-deck--vehicles .deck-subtitle-alt {
  color: #78a932;
  font-weight: bold; }

.t-deck--vehicles .deck-prev {
  left: -30px; }

.t-deck--vehicles .deck-next {
  right: -30px; }

.t-deck--vehicles .deck-prev,
.t-deck--vehicles .deck-next {
  position: absolute;
  display: block;
  height: 60px;
  width: 30px;
  line-height: 60px;
  font-size: 60px;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .t-deck--vehicles .deck-prev.slick-disabled,
  .t-deck--vehicles .deck-next.slick-disabled {
    opacity: 0.25; }
  .t-deck--vehicles .deck-prev:hover, .t-deck--vehicles .deck-prev:focus,
  .t-deck--vehicles .deck-next:hover,
  .t-deck--vehicles .deck-next:focus {
    opacity: 1; }

.t-action-bar .action-bar-link {
  display: inline-block;
  padding-left: 160px;
  height: 220px;
  padding-top: 60px;
  text-align: left;
  line-height: 28px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 130px 130px;
  transition: background 250ms;
  cursor: pointer; }
  @media (min-width: 993px) {
    .t-action-bar .action-bar-link {
      height: 260px;
      padding-top: 100px; } }
  .t-action-bar .action-bar-link:hover {
    text-decoration: none;
    background-size: 120px 120px;
    background-position-x: 20px; }

.t-action-bar .action-bar-link--trade {
  background-image: url(/assets/img/components/action-bar/icon-trade-in.png); }

.t-action-bar .action-bar-link--drive {
  background-image: url(/assets/img/components/action-bar/icon-test-drive.png); }

.t-action-bar .action-bar-link--credit {
  background-image: url(/assets/img/components/action-bar/icon-credit-app.png); }

.t-action-bar .action-bar-link-line1,
.t-action-bar .action-bar-link-line2 {
  display: block; }

.t-action-bar .action-bar-link-line1 {
  font-size: 24px;
  font-weight: bold;
  color: #183f1d; }

.t-action-bar .action-bar-link-line2 {
  font-size: 18px;
  line-height: 20px;
  color: #78a932; }

.t-action-bar .fa {
  position: relative;
  top: 2px; }

.article {
  font-size: 16px;
  text-align: center; }

.article-title {
  font-size: 30px; }

.t-thumb-gallery--vehicles .thumb-gallery-photo {
  margin-bottom: 15px; }

.t-thumb-gallery--vehicles .thumb-gallery-photo img {
  width: 100%; }

.t-thumb-gallery--vehicles .thumb-gallery-nav {
  padding: 15px 0; }

.t-thumb-gallery--vehicles .thumb-gallery-nav-item {
  margin: 15px; }

.t-thumb-gallery--vehicles .slick-dots {
  top: -10px;
  bottom: auto; }
  .t-thumb-gallery--vehicles .slick-dots li {
    margin: 0; }

.t-cta-group--vehicle .cta-group-item-link {
  background: #f4f4f4;
  color: #333;
  padding: 18px 24px;
  position: relative;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  transition: padding-left 500ms; }
  .t-cta-group--vehicle .cta-group-item-link:hover {
    background: #4f4f4f;
    color: #fff;
    padding-left: 48px;
    text-decoration: none; }
    .t-cta-group--vehicle .cta-group-item-link:hover .cta-group-item-link-icon {
      background: #4f4f4f; }

.t-cta-group--vehicle .cta-group-item-link-icon {
  display: block;
  float: right;
  background: #ccc;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 44px;
  text-align: center;
  line-height: 62px;
  font-size: 24px; }

.t-cta-group--vehicle .cta-group-item-link--green {
  background: #78a932;
  color: #fff; }
  .t-cta-group--vehicle .cta-group-item-link--green:hover {
    background: #183f1d; }
    .t-cta-group--vehicle .cta-group-item-link--green:hover .cta-group-item-link-icon {
      background: #183f1d;
      color: #fff; }
  .t-cta-group--vehicle .cta-group-item-link--green .cta-group-item-link-icon {
    background: #183f1d;
    color: #fff; }

.vehicle-card {
  overflow: hidden;
  margin-bottom: 30px; }

.vehicle-card-title {
  font-size: 32px;
  font-family: "museo-slab", serif;
  color: #183f1d;
  font-weight: bold;
  margin-bottom: 30px; }

.vehicle-card-line {
  display: block;
  font-size: 18px; }
  .vehicle-card-line label {
    width: 200px;
    display: inline-block;
    text-transform: uppercase; }

@media (max-width: 1200px) and (min-width: 992px) {
  .vehicle-card-line label {
    width: 140px; } }

.vehicle-details-line {
  display: block;
  font-size: 16px; }
  @media (max-width: 480px) {
    .vehicle-details-line {
      font-size: 14px; } }
  @media (max-width: 992px) {
    .vehicle-details-line {
      font-size: 15px; } }
  .vehicle-details-line label {
    width: 120px;
    display: inline-block; }
    @media (max-width: 480px) {
      .vehicle-details-line label {
        width: 110px; } }
    @media (max-width: 992px) {
      .vehicle-details-line label {
        width: 115px; } }

.vehicle-details-col-spacer {
  display: block;
  height: 75px; }

.vehicle-disclaimer {
  font-size: 16px;
  color: #AAA;
  margin: 20px auto 50px; }

body {
  font-family: "brandon-grotesque", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
