.action-bar{
	margin:30px 0;
}
.action-bar-block{
	border-bottom:1px solid #fff;
	background-color:#f3f3f3;
	text-align: center;
	@include media('>desktop'){
		border-bottom:none;
		border-right:1px solid #fff;
		&:last-of-type{
			border-right-width:0;
		}
	}
}
.action-bar-link{
	display: block;
	margin:0 auto;
}
.action-bar-link-line1,
.action-bar-link-line2{
	display: block;
}
.action-bar-link-line1{

}
.action-bar-link-line2{

}