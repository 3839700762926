.breadcrumb-bar{
	background: #f3f3f3;
	font-size:18px;
	color:$gray-dark;
	text-transform: uppercase;
	padding:15px 0;
	margin-bottom:30px;
	/* bs override*/
	.breadcrumb{
		margin-bottom: 0;
	}

	@include media('<=desktop'){
		li:nth-of-type(2){
			display: none;
		}
	}
	@include media('<=phone'){
		li{
			display: none;
		}
		li:first-child,
		li:nth-last-child(1),
		li:nth-last-child(2)
		//li:nth-last-child(3)
		{
			display: inline-block;
		}
	}
}
.breadcrumb-bar-item{
	color:#333;
	&:hover{
		color:#555;
		text-decoration: none;
	}
}
.breadcrumb-bar-item-active{
	cursor: default;
}
.breadcrumb-bar-action{
	padding-top: 8px;
	padding-right: 15px;
	a{
		color:#333;
		&:hover{
			color:#555;
			text-decoration: none;
		}
	}
	&:before{
		display: none;
	}
}