.t-deck--vehicles{

	.slick-dots{
		height:35px;
		top: -50px;
	}

	.deck-collection{
		margin:30px -15px;
	}
	.deck-collection--grid{
		margin-bottom:0;
	}
	.deck-card{
		padding:0 15px;
		position: relative;
	}
		.deck-card-info{
			position: absolute;
			bottom:60px;
			left:35px;
			right:35px;
			color: #fff;
			background:rgba(#000, 0.3);
			border-left: 3px solid $red;
			font-size:16px;
			line-height:20px;
			font-weight:bold;
			padding:15px;
			text-transform: uppercase;
		}
		.deck-card-info-line{
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.deck-card-button{
			width:100%;
			border-radius: 0 0 $border-radius-base $border-radius-base;
			font-size:16px;
			text-transform: uppercase;
			position: relative;
		}
		.deck-card-button-icon{
			display: block;
			width:40px;
			height:40px;
			position: absolute;
			right:-15px;
			top:50%;
			transform: translateY(-50%);
			background:transparent url(/assets/img/components/btn/circle-arrow-right-green.png) center center no-repeat;
		}

	.deck-collection--grid{
		.deck-card-button{
			@include button-variant(#333, #F3F3F3, transparent);
		}
		.deck-card-button-icon{
			background:transparent url(/assets/img/components/btn/circle-arrow-right-white.png) center center no-repeat;
		}
	}

	.deck-title{
		text-transform: uppercase;
	}
	.deck-subtitle{
		color:$dark-green;
	}
	.deck-subtitle-alt{
		color:$lime;
		font-weight:bold;
	}

	.deck-prev{
		left: -30px;
	}
	.deck-next{
		right: -30px;
	}

	.deck-prev,
	.deck-next{
		position: absolute;
		display: block;
		height: 60px;
		width: 30px;
		line-height: 60px;
		font-size: 60px;
		cursor: pointer;
		top: 50%;
		transform: translate(0, -50%);
		padding: 0;
		border: none;
		outline: none;
		&.slick-disabled{
			opacity: 0.25;
		}
		&:hover,
		&:focus{
			opacity: 1;
		}
	}

}