.callout{
	background-color:#f3f3f3;
	padding:40px;
	margin-top:30px;
	clear:both;
}
.callout-text{
	font-size:30px;
	line-height:36px;
	font-family: $font-family-serif;
	color: $dark-green;
	a{
		color:$lime;
		white-space:nowrap;
	}
}
