.feature-list{
	list-style: none;
	padding: 0;
	margin: 0;
}
.feature-list-item{
	text-indent: -10px;
	padding-right: 40px;
	margin:5px 0;
	line-height: 20px;
	.fa{
		color:green;
	}
}