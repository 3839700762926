.t-cta-group--vehicle{

	.cta-group-item-link{
		background:#f4f4f4;
		color:#333;
		padding:18px 24px;
		position: relative;
		font-size:20px;
		line-height: 26px;
		font-weight: bold;
		transition: padding-left 500ms;
		&:hover{
			background: #4f4f4f;
			color:#fff;
			padding-left: 48px;
			text-decoration: none;
			.cta-group-item-link-icon{
				background: #4f4f4f;
			}
		}
	}
	.cta-group-item-link-icon{
		display: block;
		float:right;
		background:#ccc;
		color:#fff;
		position: absolute;
		right:0;
		top:0;
		bottom:0;
		width:44px;
		text-align: center;
		line-height: 62px;
		font-size:24px;
	}

	.cta-group-item-link--green{
		background:$lime;
		color:#fff;
		&:hover{
			background:$dark-green;
			.cta-group-item-link-icon{
				background:$dark-green;
				color:#fff;
			}
		}
		.cta-group-item-link-icon{
			background:$dark-green;
			color:#fff;
		}
	}

}