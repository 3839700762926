@import "base/variables";

@import '../vendor/include-media/dist/include-media';
@import "../vendor/sass-bootstrap/lib/variables";
@import "../vendor/sass-bootstrap/lib/mixins";
@import '../vendor/bootstrap-select/dist/css/bootstrap-select';
@import '../vendor/slick-carousel/slick/slick.scss';
@import '../vendor/slick-carousel/slick/slick-theme.scss';

@import 'base/mixins';
@import 'base/utilities';
@import 'base/core';

@import 'layout/header';
@import 'layout/section';
@import 'layout/footer';

@import 'components/navbar';
@import 'components/poster';
@import 'components/tab-bar';
@import 'components/deck';
@import 'components/action-bar';
@import 'components/article';
@import 'components/tile-grid';
@import 'components/callout';
@import 'components/breadcrumb-bar';
@import 'components/cta-group';
@import 'components/filter-menu';
@import 'components/feature-list';

@import 'theme/components/deck';
@import 'theme/components/action-bar';
@import 'theme/components/article';
@import 'theme/components/thumb-gallery';
@import 'theme/components/cta-group';

@import 'theme/vehicle';

body{
	font-family: $font-family-base;
	@include font-smoothing();
}