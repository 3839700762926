.tile-grid{
	@extend %clearfix;
	margin:30px 0;

	.col-sm-6,.col-md-4,.col-md-3,.col-md-2,.col-sm-6,.col-sm-4,.col-sm-3,.col-sm-2{
		padding:0;
	}
}
.tile-grid-item{
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	padding-bottom:75%;
	overflow: hidden;
	background-position: center center;
	background-size:cover;
	background-repeat: no-repeat;

	&:after{
		position: absolute;
		top:0;
		left:0;
		content: "";
		display: block;
		height: 17px;
		width: 17px;
	}
}

$tileColors: (
	'red': $red,
	'dark-green': $dark-green,
	'lime': $lime,
);

@each $name, $color in $tileColors{

	.tile-grid-item--#{$name}{
		.tile-grid-item-cta{
			color:#{$color};
		}
		.tile-grid-item-content{
			background: rgba(#fff, 0.7);
		}
	}
	.tile-grid-item--#{$name}:after{
		background-image: url(/assets/img/components/tile-grid/triangle-#{$name}.png);
	}
}

.tile-grid-item-content{
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	padding:25px;
}

.tile-grid-item-content--vcenter{
	position: absolute;
	margin-top:50%;
	transform: translateY(-50%);
}
.tile-grid-item-title{
	color:$gray-dark;
	font-family: $font-family-serif;
	font-size:30px;
	line-height:40px;
	font-weight: normal;
	padding: 25px;
	@include media('>desktop','<=desktop-xl'){
		font-size:22px;
		line-height:28px;
	}
}
.tile-grid-item-cta{
	padding: 25px;
	text-transform: uppercase;
}