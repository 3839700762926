.tab-bar{
	@extend %listreset;
	background: $dark-green;
	min-width: 320px;
	padding: 10px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;
	display: none;

	@include media("<tablet"){
		display: table;
		table-layout: fixed;
		width: 100%;
	}

	li{
		display: table-cell;
		width: 25%;
		border-left: 1px solid darken($dark-green, 5%);
		text-align: center;
		text-transform: uppercase;

		&:first-child{
			border: none;
		}

		a{
			color: #fff;
			display: block;
			padding: 0 5px;

			&:hover{
				color: #ccc;
			}
		}

		.fa{
			display: block;
			font-size: 20px;
			margin-bottom: 5px;
		}

		.txt{
			font-size: 15px;
			line-height: 17px;
			display: block;

			@include media("<phone"){
				font-size: 11px;
				line-height: 13px;
			}
		}
	}
}

