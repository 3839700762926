.navbar-primary{

	.navbar-header{
		@include media('<=desktop'){
			float:none;
			text-align: center;
		}
	}

	.navbar-brand{
		width:auto;
		height: auto;
		padding: 30px 30px 35px $fluidPadding;
		margin-bottom: 10px;
		img{
			max-width: 271px;
			min-width:160px;
			width: 15vw;
		}
		@include media('<=desktop'){
			padding: 15px;
			width:auto;
			@include media('<=tablet'){
				text-align: center;
				width: 180px;
				padding-bottom:30px;
				float:none;
				display: block;
				margin:0 auto;
			}
			img{
				width:180px;
				@include media('<=tablet'){
					position: relative;
					left:-27px;
					margin: 0 auto;
				}
			}
		}
	}

	.navbar-toggle{
		position: absolute;
		right:0px;
		top:8px;
		border: 1px solid #ccc;
		margin-top:30px;
		margin-right:30px;
		padding:6px;
		.icon-bar{
			background:$dark-green;
		}
	}

	.navbar-nav{
		padding: 10px 0 10px;
		border-top:1px solid #EEE;
		font-size: 18px;
		@include media('>1180px'){
			padding: 50px 0 0;
			border-top-width:0;
		}
		@include media('>1380px'){
			padding: 63px 0 0;
		}
		> li{
			font-size: 18px;
			> a{
				padding: 5px 10px;
				color:#4f4f4f;
				font-weight: bold;
				text-transform: uppercase;
				border: 1px solid transparent;
				&:hover,
				&:active,
				&:focus{
					background: transparent;
					border-color: #dddddd;
				}
				&.featured{
					color: $red;
				}
			}
			@include media('>tablet'){
				//font-size:13px;
			}
			@include media('>1180px'){
				font-size:16px;
				> a{
					padding: 10px 12px;
				}
			}
			@include media('>1380px'){
				font-size:18px;
			}
		}
	}

	.navbar-contact{
		@include media('<=desktop'){
			padding-top:0;
			margin-top: -70px;
		}
		@include media('<=tablet'){
			display:none;
		}
		height: auto;
		padding: 43px $fluidPadding 0 30px;
		font-size: 18px;
		@include media('>1180px'){
			padding: 50px $fluidPadding 0 30px;
		}
		@include media('>1380px'){
			padding: 52px $fluidPadding 0 30px;
		}

		.navbar-phone{
			font-size: 20px;
			padding-left: 26px;
			color:#183f1d;
			display: block;
			background: transparent url(/assets/img/components/navbar/icon-phone.png) 0 center no-repeat;
			&:hover{
				text-decoration: none;
				cursor: default;
			}
			@include media('<=desktop'){
				padding-left:30px;
				padding-right:30px;
			}
			@include media('<=1180px'){
				background-size:20px 20px;
			}
			@include media('>1180px'){
				font-size: 24px;
				padding-left: 32px;
			}
			@include media('>1380px'){
				font-size: 36px;
				padding-left: 40px;
			}
		}
		@include media('>tablet'){
			float: right;
		}
	}

	.navbar-main{

			@include media('<=phone'){
				position: fixed;
				top: 100px;
				left: 0px;
				right: 0px;
				bottom: 0px;
				max-height: none;
				z-index: 100;
				background:#FFF;
			}
		> .navbar-nav{

			@include media('<=1180px'){
				clear:left;
			}
			@include media('>tablet'){
				float: none;
				text-align: center;
				> li{
					float: none;
					display: inline-block;
				}
			}
		}
	}

	.navbar-inventory{
		width: 100%;
		padding:0;
		margin-top: -1px !important;
		background:#f3f3f3;
		border-width:2px 0 0;
		border-color:$red;
		@include media('>tablet'){
			width:650px;
			float:left;
		}
		a{
			text-decoration: none;
			&:hover{
				color:#78a932;
			}
		}
		.navbar-cats,
		.navbar-links{
			@extend %listreset;
			width:100%;
			float:none;
			overflow: hidden;
		}
		.navbar-cats{
			@include media('>tablet'){
				width:360px;
				margin-bottom: 32px;
				float:right;
				}
			}
			.navbar-cat a{
				display: block;
				width: 140px;
				height: 100px;
				float: left;
				background-image: url(/assets/img/components/navbar/nav-sprite-vehicles.png);
				margin: 0 8px;
				background-position-y: -10px;
				padding: 72px 0 0;
				text-align: center;
				&:hover{
					background-position-y: 111px;
				}
				@include media('>tablet'){
					margin: 0 20px;
				}
			}
			.navbar-cat-cars a{
				background-position-x: 0;
				}
			.navbar-cat-vans a{
				background-position-x: 140px;
			}
			.navbar-cat-trucks a{
				background-position-x: 420px;
			}
			.navbar-cat-suvs a{
				background-position-x: 280px;
			}
		.navbar-links{
			margin: 10px 0;
			text-align: left;
			@include media('>tablet'){
				width:288px;
				float:left;
				border-right:1px solid #FFF;
				margin: 20px 0;
				}
			}
			a{
				display: block;
				white-space: nowrap;
				padding:9px 20px;
				color:#4f4f4f;
				font-size:16px;
				text-transform: uppercase;
			}
	}

}
