.t-thumb-gallery--vehicles{

	.thumb-gallery-photo{
		margin-bottom:15px;
	}

	.thumb-gallery-photo img{
		width:100%;
	}

	.thumb-gallery-nav{
		padding: 15px 0;
	}
	.thumb-gallery-nav-item{
		margin: 15px;
	}

	.slick-dots{
		top: -10px;
		bottom:auto;
		li{
			margin:0;
		}
	}

}