
.filter-menu-top-spacer{
	> .filter-menu{
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

.filter-menu{
	//margin-bottom: 60px;
	padding: 0;
	font-size:18px;

	.nav-label {
		@include media('<=phone'){
			clear:both;
			margin-left:0;
			width:80px;
		}
		span{
			cursor: default !important;
			padding:10px 0 10px 10px;
			display: block;
		}
	}

	&.nav-pills>li>a{
		border-radius:0;
		border-bottom: 1px solid #eee;
		background:transparent;
		padding:10px;
		&:hover{
			border-bottom: 1px solid $red;
			background:transparent;
		}
		&:focus{
			background-color: transparent;
		}
	}

	&.btn-group > .btn{
		float: none;
		font-size: 16px;
		margin-left: 5px;
		margin-right: 5px;
		padding: 0;
		text-decoration: none !important;
		border-radius:0;
		& > a{
			border-bottom: 1px solid #eee;
			display: inline-block;
			padding: 6px 12px;
			&:hover{
				border-bottom: 1px solid $red;
			}
		}
		&.active,
		&:active{
			background-color: transparent;
			-webkit-box-shadow: none;
			box-shadow: none;
			> a{
				border-bottom: 2px solid $red;
			}
		}
	}

}
