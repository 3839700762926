// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// clearfix
%clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}


// transition
%transition {
	transition: all 0.15s ease-in-out;
}

// justify nav
%justify {
	text-align: justify;
	font-size: 1px;
	line-height: 0px;
	> * {
		display: inline-block;
		vertical-align: top;
		text-align: left;
		font-size: $font-size-base;
		line-height: $line-height-base;
	}
	&:after {
		content: '';
		width: 100%;
		display: inline-block;
		vertical-align: top;
	}
}

.u-margin-x-0,
.u-margin-left-0{
	margin-left:0 !important;
}
.u-margin-x-0,
.u-margin-right-0{
	margin-right:0 !important;
}
.u-margin-y-0,
.u-margin-top-0{
	margin-top:0 !important;
}
.u-margin-y-0,
.u-margin-bottom-0{
	margin-bottom:0 !important;
}

.u-undermark{
	&:after{
		content: "";
		display: block;
		height: 4px;
		width: 100px;
		background: $gray-dark;
		margin: 0.6em auto;
	}
}
.u-undermark--margin-bottom-0{
	&:after{
		margin-bottom:0;
	}
}
.u-undermark--margin-top-0{
	&:after{
		margin-top:0;
	}
}
.u-undermark--margin-x-0{
	&:after{
		margin-left:0;
		margin-right:0;
	}
}
.u-undermark--red:after{ background: $red; }
.u-undermark--dark-green:after{ background: $dark-green; }
.u-undermark--lime:after{ background: $lime; }

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}

