.cta-group{
	@extend %listreset;
	overflow: hidden;
}
.cta-group-item{
	display: block;
	margin-bottom:1px;
}
.cta-group--horizontal{
	.cta-group-item{
		float:left;
	}
}
.cta-group-item-link{
	@extend .btn;
	display: block;
}