$poster-btn-padding: 10px 20px;
$poster-ratios: (
		4:3,
		16:9,
		2:1,
		3:1,
		8:3,
		30:13,
);

.poster{
	position: relative;
	background: transparent none center center no-repeat;
	background-size: cover;
	z-index: 20;
}

.poster--overlay{
	&:after{
		content: "";
		display: block;
		position: absolute;
		background: rgba(#000, 0.6);
		top:0;
		left:0;
		bottom:0;
		right:0;
		z-index: 30;
	}
}

.poster--center{
	text-align: center;
}

.poster-body{
	position: relative;
	z-index: 40;
}

@each $x, $y in $poster-ratios{
	$perc: floor(($y/$x)*100);
	.poster--#{$x}x#{$y}{
		min-height: #{$perc}vw;
	}
	.poster--#{$x}x#{$y}.poster--vcenter{
		padding: 0 30px;
		padding-top: #{$perc / 2}vw;
		.poster-body{
			transform: translateY(-50%);
		}
	}
}

.poster-title{
	font-family: $font-family-serif;
	font-weight: bold;
	@include fluid-type(font-size, 400px, 1024px, 24px, 64px);
	color: #fff;
	margin:0;
}

.poster-form{
	max-width: 624px;
	background: rgba(255,255,255,0.3);
	padding: 30px;
	margin: 0 auto;
	border-radius: 3px;
	font-size:14px;

	// Vendor overrides
	.btn{
		padding: $poster-btn-padding;
		text-transform: uppercase;
	}
	.btn-group{
		margin-left: 5px;
		margin-right: 5px;
	}
	.select-custom{
		.btn{
			color: #333;
			background-color: #fff;
			border-color: transparent;
			padding-right: 25px;
		}
		.dropdown-menu{
			border-radius: 3px;
			text-align: left;
			text-transform: uppercase;
		}
	}

}

.poster-cta{
	display: none;
	padding: $poster-btn-padding;
	margin-top: 1.2em;
	@include media('>tablet'){
		display: inline-block;
	}
	@include media('>desktop'){
		margin-top: calc(30px + 1.2em);
	}
	text-transform: uppercase;
}