.vehicle-card{
	overflow: hidden;
	margin-bottom: 30px;
}
.vehicle-card-title{
	font-size:32px;
	font-family: $font-family-serif;
	color: $dark-green;
	font-weight: bold;
	margin-bottom: 30px;
}

.vehicle-card-line{
	display: block;
	font-size:18px;
	label{
		width:200px;
		display: inline-block;
		text-transform: uppercase;
		@include media('<=1200px', '>=desktop'){
			width:140px;
		}
	}
}

.vehicle-details-line{
	display: block;
	font-size: 16px;
	@include media('<=phone'){
		font-size: 14px;
	}
	@include media('<=desktop'){
		font-size: 15px;
	}
	label{
		width: 120px;
		@include media('<=phone'){
			width: 110px;
		}
		@include media('<=desktop'){
			width: 115px;
		}
		display: inline-block;
	}

}
.vehicle-details-col-spacer{
	display: block;
	height: 75px;
}
.vehicle-disclaimer{
	font-size: 16px;
	color: #AAA;
	margin: 20px auto 50px;
}