.footer{
	background: #1d5100;
	color:#1d5100;
	padding:30px 0 50px;
	@include media('<=tablet'){
		margin-bottom: 58px;
	}
	.footer-heading{
		font-weight:bold;
		font-family: $font-family-serif;
		font-size:20px;
		margin:30px 0;
		color:$lime;
		text-transform: uppercase;
	}
	.footer-contact,
	.footer-hours,
	.footer-links{
		font-size:14px;
		line-height: 24px;
		color: #fff;
		a{
			display: block;
			color:#fff;
		}
	}
	.footer-hours,
	.footer-links{
		text-transform: uppercase;
	}
}