.deck{
}
.deck-card{
	display: none;
	&:focus{
		outline: none;
	}
	.img-responsive{
		width:100%;
	}
}
.deck-collection--grid{
	overflow: hidden;
}
	.deck-card{
		display: block;
		float:left;
		width:100%;
		margin-bottom:30px;
		@include media('>phone'){
			width:50%;
		}
		@include media('>desktop'){
			width:33.333%;
		}
	}

.deck-title,
.deck-subtitle{
	margin:60px 0;
	font-weight:bold;
	font-size:30px;
	line-height:34px;
	font-family: $font-family-serif;
	color:$gray-dark;
	text-align: center;
	clear:both;
}
.deck-subtitle{
	font-size:28px;
	line-height:32px;
	font-weight:normal;
}