// Common layout dimensions
$fluidPadding: 5vw; // Padding on left and right of fluid containers

// custom variables and override bootstrap variables

$white: #fff;
$black: #000;
$blue: #0c436f;
$red: #ce2026;
$dark-green: #183f1d;
$lime: #78a932;

$brand-danger: $red;

//Media queries breakpoints
$breakpoints: (
		'phone-xs': 320px,
		'phone': 480px,
		'tablet': 768px,
		'desktop': 992px,
		'desktop-xl': 1200px
);

// keywords
$media-expressions: (
		'screen': 'screen',
		'print': 'print',
		'handheld': 'handheld',
		'landscape': '(orientation: landscape)',
		'portrait': '(orientation: portrait)',
		'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
		'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

//Container sizes
$grid-gutter-width: 30px;
$container-sm: 700px + $grid-gutter-width;
$container-large-desktop: 1170px + $grid-gutter-width;

//Scaffolding
$body-bg: $white;
$text-color: #3f3f3f;
$link-color: $blue;
$link-hover-color: $red;

//Typography
$font-family-serif: "museo-slab", serif;
$font-family-brandon: "brandon-grotesque", sans-serif;
$font-family-base: $font-family-brandon;
$font-size-base: 16px;
$line-height-base: 1.875;
$font-size-h2: 28px;
$font-size-h3: 16px;
$headings-font-family: $font-family-brandon;
$headings-font-weight: 400;
$headings-color: #333;

//Buttons
$btn-font-weight: 600;
$btn-default-color: #0d456e;
$btn-default-bg: #d5d5d5;
$btn-default-border: transparent;
$border-radius-base: 3px;

//Navbar
$navbar-margin-bottom: 0;
$navbar-border-radius:none;
$navbar-default-color: #0d456e;
$navbar-default-bg: $white;
$navbar-default-border: none;
$navbar-default-link-color: #0d456e;
$navbar-default-link-hover-color: #0d456e;
$navbar-default-link-hover-bg: #f3f6f4;
$navbar-default-link-active-color: #0d456e;
$navbar-default-link-active-bg: #f3f6f4;
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-icon-bar-bg: #0d456e;
$navbar-default-toggle-border-color: transparent;

// Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-color:#0c436f;
$breadcrumb-active-color: #e2971d;
$breadcrumb-padding-vertical: 0;

//Thumbnails
$thumbnail-border: none;
$thumbnail-border-radius: none;
$thumbnail-bg: transparent;
$thumbnail-padding: 0;
$thumbnail-caption-color: #3f3f3f;
$thumbnail-caption-padding: 5px;


// Pagination
// -------------------------

$pagination-bg:                        #fff;
$pagination-border:                    #ddd;
$pagination-hover-bg:                  #DDD;
$pagination-active-bg:                 $lime;
$pagination-active-color:              #fff;
$pagination-disabled-color:            #DDD;